import { TooltipProvider } from "@resume-plus/ui";
import { QueryClientProvider } from "@tanstack/react-query";
import { useEffect } from "react";
import * as ReactGA from "react-ga";
import { HelmetProvider } from "react-helmet-async";
import { Outlet, useLocation } from "react-router-dom";

import { helmetContext } from "../constants/helmet";
import { queryClient } from "../libs/query-client";
import { AuthRefreshProvider } from "./auth-refresh";
import { DialogProvider } from "./dialog";
import ErrorBoundary from "./error";
import { LocaleProvider } from "./locale";
import { ThemeProvider } from "./theme";
import { Toaster } from "./toaster";

export const Providers = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return (
    <LocaleProvider>
      <HelmetProvider context={helmetContext}>
        <QueryClientProvider client={queryClient}>
          <AuthRefreshProvider>
            <ThemeProvider>
              <TooltipProvider>
                <ErrorBoundary>
                  <DialogProvider>
                    <Outlet />
                    <Toaster />
                  </DialogProvider>
                </ErrorBoundary>
              </TooltipProvider>
            </ThemeProvider>
          </AuthRefreshProvider>
        </QueryClientProvider>
      </HelmetProvider>
    </LocaleProvider>
  );
};
